import ReactDOM from "react-dom/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import CheckoutForm from "./pages/CheckoutForm";
import { App } from "./app";
import Confirmation from "./components/confirmationCode/confirmation";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const stripePromise = loadStripe('pk_test_51MxnYII27mDSpXH8iHC7L2YQ7C0lEn3QEejx1gOQj29EmDUf24qsgJWHMN9y0muw3RwtsJcyj6pK8CNKM0Fke56q00UZADxfns');

// const options = {
//     // passing the client secret obtained from the server
//     clientSecret: 'sk_test_51MxnYII27mDSpXH87J3FGM5ycWYR2LTqvS348VY4Dgrkb6G4w0p58xawfgQHjJ2RtDhcNkz5pvcrIYjlx5NvQMXu00CoRe5vDO',
//   };

root.render(
  // <Elements stripe={stripePromise}>
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
        {/* <Confirmation/> */}
        {/* <CheckoutForm/> */}
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
  // </Elements>
);
