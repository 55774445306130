/** @format */

import {
  Box,
  Chip,
  Container,
  Divider,
  Stack,
  Switch,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { PricingPlan } from "src/sections/pricing/pricing-plan";
import { PricingPlanIcon } from "src/sections/pricing/pricing-plan-icon";

const Page = () => {
  usePageView();

  return (
    <>
      <Seo title="Pricing" />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "neutral.800" : "neutral.50",
            pb: "120px",
            pt: "184px",
          }}
        >
          <Container maxWidth="lg" style={{ justifyContent: "center" }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                mb: 4,
                justifyContent: "center",
              }}
            >
              <Typography variant="h3">Active plan</Typography>
            </Box>
            <Grid container spacing={4} style={{ justifyContent: "center" }}>
              <Grid xs={12} md={4} style={{ justifyContent: "center" }}>
                <PricingPlan
                  cta="Upgrade plan"
                  // cta={<a href="/subscriptionplan" style={{ color: "white" }}>Upgrade plan</a>}
                  currency="$"
                  description="To familiarize yourself with our tools."
                  features={[
                    "All previous",
                    "Highlights reporting",
                    "Data history",
                    "Unlimited users",
                  ]}
                  icon={<PricingPlanIcon Name="standard" />}
                  name="Standard"
                  popular
                  price="4.99"
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                    justifyContent: "center",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default Page;
