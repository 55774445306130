/** @format */

import PropTypes from "prop-types";
import SunIcon from "@untitled-ui/icons-react/build/esm/Sun";
import Moon01Icon from "@untitled-ui/icons-react/build/esm/Moon01";
import { Chip, Stack, SvgIcon, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { SettingsContext } from "src/contexts/settings-context";

export const ThemeSwitch = (props) => {
  const { onChange, value } = props;
  const { handleUpdate } = useContext(SettingsContext);
  const [isDarkMode, setIsDarkMode] = useState(value === "dark");

  const handleThemeToggle = () => {
    const newTheme = isDarkMode ? "light" : "dark";
    setIsDarkMode(!isDarkMode);
    handleUpdate({
      paletteMode: newTheme,
    });
  };

  return (
    <Stack spacing={1}>
      <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
        <Chip
          icon={
            <SvgIcon fontSize="small">
              {isDarkMode ? <SunIcon /> : <Moon01Icon />}
            </SvgIcon>
          }
          label={isDarkMode ? "Light" : "Dark"}
          onClick={handleThemeToggle}
          sx={{
            borderColor: "transparent",
            borderRadius: 1.5,
            borderStyle: "solid",
            borderWidth: 2,
            ...(isDarkMode
              ? value === "light" && {
                  borderColor: "primary.main",
                }
              : value === "dark" && {
                  borderColor: "primary.main",
                }),
          }}
        />
      </Stack>
    </Stack>
  );
};

ThemeSwitch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(["light", "dark"]),
};