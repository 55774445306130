export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },
  nav: {
    academy: "nav.academy",
    logout: "nav.Logout",
    logout1: "nav.",

    requestSupport: "nav.Request Support",
    account: "nav.account",
    integrations: "nav.integrations",
    analytics: "nav.analytics",
    auth: "nav.auth",
    blog: "nav.blog",
    browse: "nav.browse",
    calendar: "nav.calendar",
    chat: "nav.chat",
    checkout: "nav.checkout",
    concepts: "nav.concepts",
    contact: "nav.contact",
    course: "nav.course",
    create: "nav.create",
    crypto: "nav.crypto",
    customers: "nav.customers",
    dashboard: "nav.dashboard",
    details: "nav.details",
    ecommerce: "nav.ecommerce",
    edit: "nav.edit",
    error: "nav.error",
    feed: "nav.feed",
    fileManager: "nav.fileManager",
    files: "nav.files",
    finance: "nav.finance",
    fleet: "nav.fleet",
    forgotPassword: "nav.forgotPassword",
    invoiceList: "nav.invoices",
    jobList: "nav.jobList",
    kanban: "nav.kanban",
    list: "nav.list",
    login: "nav.login",
    logistics: "nav.logistics",
    mail: "nav.mail",
    management: "nav.management",
    orderList: "nav.orders",
    overview: "nav.overview",
    pages: "nav.pages",
    postCreate: "nav.postCreate",
    postDetails: "nav.postDetails",
    postList: "nav.postList",
    pricing: "nav.pricing",
    productList: "nav.products",
    profile: "nav.profile",
    register: "nav.register",
    unsubscribe: "nav.unsubscribe",
    subscription: "nav.subscription",
    resetPassword: "nav.resetPassword",
    socialMedia: "nav.socialMedia",
    verifyCode: "nav.verifyCode",
    invoicedetails: "nav.invoicedetails",
    subscriptionplan: "nav.subscriptionplan",
    activeplan: "nav.activeplan",
  },
};
