import React from 'react'

export default function confirmation() {
  return (
    <div className='container-fluid'>
<div className='row d-flex justify-content-center'>
    <div className = "col-md-6">
    <div class="card">
  <div class="card-body">
   <h3>Enter Your Confirmation Code</h3>
 <input type = "text" className='w-100'/>
 <button> Submit</button>

  </div>
</div>
    </div>
</div>
    </div>
  )
}
