import { useState } from "react";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import { Avatar, Box, ButtonBase, SvgIcon } from "@mui/material";
import { useMockedUser } from "src/hooks/use-mocked-user";
import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";
import { useAuth } from "../../../../src/hooks/use-auth";
import { useEffect } from "react";
import axios from "axios";
import { API_URLS } from "src/api-url/API_URLS";
import Cookies from "js-cookie";
export const AccountButton = () => {
  const user = useMockedUser();
  const popover = usePopover();
  const { profileImage } = useAuth();

  const [getAvtar, setGetAvtar] = useState(profileImage);
  const getUserProfile = async () => {
    try {
      var options = {
        method: "GET",
        url: `${API_URLS.getUserProfile}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      };

      let response = await axios.request(options);
      let userInfo = response.data;

      setGetAvtar(response?.data?.profileImage);
    } catch (error) {}
  };

  useEffect(() => {
    getUserProfile();
  }, [profileImage]);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        {getAvtar ? (
          <Avatar
            sx={{
              height: 32,
              width: 32,
            }}
            src={getAvtar}
          >
            <SvgIcon>
              <User01Icon />
            </SvgIcon>
          </Avatar>
        ) : (
          <Avatar
            sx={{
              height: 32,
              width: 32,
            }}
            src={user.avatar}
          >
            <SvgIcon>
              <User01Icon />
            </SvgIcon>
          </Avatar>
        )}
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
