/** @format */

import "react-toastify/dist/ReactToastify.css";
import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";
import { Issuer } from "src/utils/auth";
import { API_URLS } from "src/api-url/API_URLS";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { first } from "lodash";

const STORAGE_KEY = "accessToken";
var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, customer } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      customer,
    };
  },
  SIGN_IN: (state, action) => {
    const { user, customer } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      customer,
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [csvVariable, setCsvVariable] = useState(1);
  const [intialDate, setIntialDate] = useState("");
  const [userIdNumber, setUserIdNumber] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [loginDetails, setLoginDetails] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const initialize = useCallback(async () => {
    try {
      const accessToken =
        window.sessionStorage.getItem(STORAGE_KEY) ||
        Cookies.get("access_token");

      if (accessToken) {
        // const user = await authApi.me({ accessToken });
        const user = await authApi.newMe({ accessToken });

        if (user?.user?.isEmailVerified) {
          setUserIdNumber(user?.user?.id);
          const createCustomer = await authApi.createCustomer({
            email: user?.user?.email,
          });
          let customer = createCustomer?.customer;

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: user?.user,
              customer,
            },
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  const newSignIn = useCallback(
    async (email, password) => {
      const signInDetails = await authApi.newSignIn({ email, password });
      const createCustomer = await authApi.createCustomer({ email });
      if (signInDetails?.user?.isEmailVerified) {
        let customer = createCustomer?.customer;
        setLoginDetails(signInDetails);
        let accessToken = signInDetails?.tokens?.access?.token;
        sessionStorage.setItem(STORAGE_KEY, accessToken);
        Cookies.set("access_token", accessToken);
        let user = signInDetails?.user;
        sessionStorage.setItem("user_details", user);
        setUserIdNumber(user?.id);

        var parts = signInDetails?.user?.name?.split(" ");
        setFirstName(parts[0]);
        setLastName(parts[1]);
        setEmail(signInDetails?.user?.email);

        if (user) {
          dispatch({
            type: ActionType.SIGN_IN,
            payload: {
              user,
              customer,
            },
          });
        }
      }

      return signInDetails;
    },
    [dispatch]
  );

  const handleCsvChange = (item) => {
    setCsvVariable(item);
    localStorage.setItem("csvNoItem", item);
  };

  const signIn = useCallback(
    async (email, password) => {
      const { accessToken } = await authApi.signIn({ email, password });
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const newSignUp = useCallback(
    async (email, name, password) => {
      const signUpDetails = await authApi.newSignUp({ email, name, password });
      let accessToken = signUpDetails?.tokens?.access?.token;

      let user = signUpDetails
        ? signUpDetails?.user
        : { email, name, password };

      // localStorage.setItem(STORAGE_KEY, accessToken);
      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );
  const sendMail = useCallback(
    async (request) => {
      const signUpDetails = await authApi.verificationMail();

      // let accessToken = signUpDetails?.tokens?.access?.token;
      toast.success(
        <div>
          <h4 style={{ color: "black", fontWeight: "600" }}>
            Verify Your email
          </h4>
          <p style={{ textAlign: "center" }}>
            We sent an email to {request}.<br /> Click the link inside to get
            started.
          </p>
        </div>
      );
      // sessionStorage.setItem(STORAGE_KEY, accessToken);
    },
    [dispatch]
  );
  const signUp = useCallback(
    async (email, name, password) => {
      const { accessToken } = await authApi.signUp({ email, name, password });
      const user = await authApi.me({ accessToken });
      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const changeProfile = async (avtar, email) => {
    var formdata = new FormData();
    formdata.append("profileImage", avtar);

    await axios
      .put(`${API_URLS.update_profile}`, formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      })
      .then((res) => {
        getUserProfile();
      })
      .catch((err) => {});
  };
  const getUserProfile = async () => {
    try {
      const response = await axios.get(`${API_URLS.getUserProfile}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      });

      setProfileImage(response.data?.profileImage);
    } catch (error) {}
  };
  const defaultDate = async (value, type) => {
    try {
      const response = await axios.get(
        `${API_URLS.getDefaultDate}?import_type=shopify&import_source=${type}&import_count=${value}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${Cookies.get("access_token")}`,
          },
        }
      );

      setIntialDate(response?.data?.data[0]);
    } catch (error) {}
  };

  const signOut = useCallback(async () => {
    sessionStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem("csvNoItem");
    Cookies.remove("access_token");

    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const forgotPassword = async (email) => {
    let forgotPassword = await authApi.forgotPassword({ email });

    return forgotPassword;
  };

  const forgotPasswordSubmit = async (token, password) => {
    let resetPassword = await authApi?.resetPassword({ token, password });
  };
  useEffect(() => {
    initialize();
    getUserProfile();
    setCsvVariable(localStorage.getItem("csvNoItem"));
    // defaultDate();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut,
        newSignUp,
        newSignIn,
        loginDetails,
        sendMail,
        changeProfile,
        forgotPassword,
        forgotPasswordSubmit,
        handleCsvChange,
        csvVariable,
        profileImage,
        intialDate,
        defaultDate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
