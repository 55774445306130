// import PropTypes from 'prop-types';
// import {
//   Box,
//   Checkbox,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   Stack,
//   TextField,
//   Typography,
//   Unstable_Grid2 as Grid
// } from '@mui/material';

// const paymentMethods = [
//   {
//     label: 'Visa Credit/Debit Card',
//     value: 'visa'
//   },
//   {
//     label: 'PayPal',
//     value: 'paypal'
//   }
// ];

// export const CheckoutBilling = (props) => {
//   const { billing, onChange, ...other } = props;

//   return (
//     <Stack {...other}
//            spacing={6}
//     >
//       <Stack spacing={3}>
//         <Stack
//           alignItems="center"
//           direction="row"
//           spacing={2}
//         >
//           <Box
//             sx={{
//               alignItems: 'center',
//               border: (theme) => `1px solid ${theme.palette.divider}`,
//               borderRadius: 20,
//               display: 'flex',
//               height: 40,
//               justifyContent: 'center',
//               width: 40
//             }}
//           >
//             <Typography
//               sx={{ fontWeight: 'fontWeightBold' }}
//               variant="h6"
//             >
//               1
//             </Typography>
//           </Box>
//           <Typography variant="h6">
//             Billing Address
//           </Typography>
//         </Stack>
//         <div>
//           <Grid
//             container
//             spacing={3}
//           >
//             <Grid
//               xs={12}
//               sm={6}
//             >
//               <TextField
//                 fullWidth
//                 label="First Name"
//                 name="firstName"
//                 onChange={onChange}
//                 value={billing.firstName}
//               />
//             </Grid>
//             <Grid
//               xs={12}
//               sm={6}
//             >
//               <TextField
//                 fullWidth
//                 label="Last Name"
//                 name="lastName"
//                 onChange={onChange}
//                 value={billing.lastName}
//               />
//             </Grid>
//             <Grid
//               xs={12}
//               sm={6}
//             >
//               <TextField
//                 fullWidth
//                 label="Street Address"
//                 name="address"
//                 onChange={onChange}
//                 value={billing.address}
//               />
//             </Grid>
//             <Grid
//               xs={12}
//               sm={6}
//             >
//               <TextField
//                 fullWidth
//                 label="Street Line 2 (optional)"
//                 name="optionalAddress"
//                 onChange={onChange}
//                 value={billing.optionalAddress}
//               />
//             </Grid>
//             <Grid
//               xs={12}
//               sm={3}
//             >
//               <TextField
//                 fullWidth
//                 label="State"
//                 name="state"
//                 onChange={onChange}
//                 value={billing.state}
//               />
//             </Grid>
//             <Grid
//               xs={12}
//               sm={3}
//             >
//               <TextField
//                 fullWidth
//                 label="Zip"
//                 name="zip"
//                 onChange={onChange}
//                 value={billing.zip}
//               />
//             </Grid>
//           </Grid>
//         </div>
//       </Stack>
//       <Stack spacing={3}>
//         <Stack
//           alignItems="center"
//           direction="row"
//           spacing={2}
//         >
//           <Box
//             sx={{
//               alignItems: 'center',
//               border: (theme) => `1px solid ${theme.palette.divider}`,
//               borderRadius: 20,
//               display: 'flex',
//               height: 40,
//               justifyContent: 'center',
//               width: 40
//             }}
//           >
//             <Typography
//               sx={{ fontWeight: 'fontWeightBold' }}
//               variant="h6"
//             >
//               2
//             </Typography>
//           </Box>
//           <Typography variant="h6">
//             Shipping Address
//           </Typography>
//         </Stack>
//         <div>
//           <FormControlLabel
//             control={<Checkbox defaultChecked />}
//             label="Same as billing address"
//           />
//         </div>
//       </Stack>
//       <Stack spacing={3}>
//         <Stack
//           alignItems="center"
//           direction="row"
//           spacing={2}
//         >
//           <Box
//             sx={{
//               alignItems: 'center',
//               border: (theme) => `1px solid ${theme.palette.divider}`,
//               borderRadius: 20,
//               display: 'flex',
//               height: 40,
//               justifyContent: 'center',
//               width: 40
//             }}
//           >
//             <Typography
//               sx={{ fontWeight: 'fontWeightBold' }}
//               variant="h6"
//             >
//               3
//             </Typography>
//           </Box>
//           <Typography variant="h6">
//             Payment Method
//           </Typography>
//         </Stack>
//         <div>
//           <div>
//             <RadioGroup
//               name="paymentMethod"
//               onChange={onChange}
//               sx={{ flexDirection: 'row' }}
//               value={billing.paymentMethod}
//             >
//               {paymentMethods.map((paymentMethod) => (
//                 <FormControlLabel
//                   control={<Radio />}
//                   key={paymentMethod.value}
//                   label={(
//                     <Typography variant="body1">
//                       {paymentMethod.label}
//                     </Typography>
//                   )}
//                   value={paymentMethod.value}
//                 />
//               ))}
//             </RadioGroup>
//           </div>
//           <div>
//             <Grid
//               container
//               spacing={3}
//             >
//               <Grid
//                 xs={12}
//                 sm={6}
//               >
//                 <TextField
//                   fullWidth
//                   label="Name on Card"
//                   name="cardOwner"
//                   onChange={onChange}
//                   value={billing.cardOwner}
//                 />
//               </Grid>
//               <Grid sm={6} />
//               <Grid
//                 xs={12}
//                 sm={6}
//               >
//                 <TextField
//                   fullWidth
//                   label="Card Number"
//                   name="cardNumber"
//                   onChange={onChange}
//                   value={billing.cardNumber}
//                 />
//               </Grid>
//               <Grid sm={6} />
//               <Grid
//                 xs={12}
//                 sm={3}
//               >
//                 <TextField
//                   fullWidth
//                   label="Expire Date"
//                   name="cardExpirationDate"
//                   onChange={onChange}
//                   placeholder="MM/YY"
//                   value={billing.cardExpirationDate}
//                 />
//               </Grid>
//               <Grid
//                 xs={12}
//                 sm={3}
//               >
//                 <TextField
//                   fullWidth
//                   label="Security Code"
//                   name="cardSecurityCode"
//                   onChange={onChange}
//                   value={billing.cardSecurityCode}
//                 />
//               </Grid>
//             </Grid>
//           </div>
//         </div>
//       </Stack>
//     </Stack>
//   );
// };

// CheckoutBilling.propTypes = {
//   billing: PropTypes.object,
//   onChange: PropTypes.func
// };

import * as Yup from "yup";
import React, { useEffect, useState } from "react";

import {
  Checkbox,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  SvgIcon,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import axios from "axios";
import { API_URLS } from "src/api-url/API_URLS";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../src/hooks/use-auth";
import { AccountPlanIcon } from "../../../src/sections/dashboard/account/account-plan-icon";
import {
  errorPopup,
  successPopup,
} from "../../../src/components/popups/popups";
import CheckIcon from "@untitled-ui/icons-react/build/esm/Check";
import { PropertyListItem } from "../../../src/components/property-list-item";
import numeral from "numeral";
import { PropertyList } from "../../../src/components/property-list";

export const CheckoutBilling = (props) => {
  const currentPlan = "standard";
  const invoices = [];
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const location = useLocation();
  const [token, setToken] = useState("");
  const [amount, setamount] = useState([]);
  const [isAnnual, setIsAnnual] = useState(false);
  const [data, setData] = useState("monthly");
  const createCustomer = useAuth();
  const endDate = new Date(createCustomer?.customer?.endDate);
  const formattedDate = endDate.toLocaleDateString("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  let id = createCustomer?.customer?.billingID;
  let details = createCustomer?.customer;
  let basicplan = createCustomer?.customer?.plan;
  let subscription = createCustomer?.customer?.subscription;
  let email = createCustomer?.customer?.email;

  let billingId = createCustomer?.customer?.billingID;

  const handleChange = () => {
    if (!isAnnual) {
      setData("yearly");
      setIsAnnual(!isAnnual);
    } else {
      setData("monthly");
      setIsAnnual(!isAnnual);
    }
  };
  const checkout = (e, product) => {
    e.preventDefault();

    let body = {
      email: createCustomer?.customer?.email,
      subscription: data,
      product: "enterprise",
      customerID: createCustomer?.customer?.billingID,
    };
    axios
      .post(`${API_URLS.planCheckout}`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        window.location.replace(res?.data?.redirectURL);
      })
      .catch((err) => {});
  };
  const statuscheck = (e) => {
    axios
      .post(
        `${API_URLS.checkoutstatus}`,
        {
          email: email,
          customer: billingId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        // window.location.replace(res?.data?.url);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const plans = [
    {
      id: "basic",
      icon: <AccountPlanIcon name="basic" />,
      name: "Growth",
      price: isAnnual ? "$9999.00/Year" : "$999.00/Month",
      features: [
        "Total Orders Analyzed	25,000",
        "Shopify Store Connect",
        "CSV Order Import",
        "CSV Data Export",
        "Opportunity Sizing",
        "User Migration Heatmap",
        "Decile Analysis",
        "Retention Cohort",
        "Lifetime Value Cohorts",
        "RFM Segmentation",
        "Weekly Email Alert",
        "Data Analytics As Service",
      ],
      buttontittle: "Buy Now",
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      // Do something with the token (e.g., send it to a backend API for verification)
      setToken(token);
    }
    // Token is missing, handle the error or redirect to an appropriate page
    // Redirect to the forgot password page
  }, []);

  return (
    <>
      <Seo title="Register" />
      <div>
        <Card elevation={16}>
          <CardHeader
            title="Change Plan"
            subheader="You can upgrade and downgrade whenever you want"
          />
          <div
            md="12"
            className="mt-4 text-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                data-bs-toggle="collapse"
                data-bs-target="#collapseUser"
                aria-expanded={isAnnual}
                aria-controls="collapseUser"
                onChange={handleChange}
                checked={isAnnual}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                {isAnnual ? "Annually" : "Monthly"}
              </label>
            </div>

            <div className="collapse" id="collapseUser"></div>
          </div>
          <CardContent sx={{ pt: 0 }}>
            <div>
              <Grid containerFluid>
                {plans.map((plan) => {
                  const isSelected = plan.id === selectedPlan;
                  const isCurrent = plan.id === currentPlan;
                  const price = numeral(plan.price).format("$0,0.00");

                  return (
                    <Grid key={plan.id} xs={12} sm={12} sx={{ height: 680 }}>
                      <Card
                        onClick={() => setSelectedPlan(plan.id)}
                        sx={{
                          cursor: "pointer",
                          marginTop: "16px",
                          ...(isSelected && {
                            borderColor: "primary.main",
                            borderWidth: 2,
                            m: "-1px",
                          }),
                        }}
                        variant="outlined"
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: "16px",
                            }}
                          >
                            <Box
                              sx={{
                                height: 70,
                                width: 52,
                                "& img": {
                                  height: "auto",
                                  width: "100%",
                                },
                              }}
                            >
                              {plan.icon}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                mb: 1,
                                mt: 1,
                              }}
                            >
                              <Typography variant="h5">{price}</Typography>
                              <Typography
                                color="text.secondary"
                                sx={{
                                  mt: "auto",
                                  ml: "4px",
                                }}
                                variant="body2"
                              >
                                {isAnnual ? "/year" : "/Month"}
                              </Typography>
                            </Box>
                            <Stack
                              alignItems="center"
                              direction="row"
                              justifyContent="space-between"
                              spacing={3}
                            >
                              <Typography variant="overline">
                                {plan.name}
                              </Typography>
                              {isCurrent && (
                                <Typography
                                  color="primary.main"
                                  variant="caption"
                                >
                                  Using now
                                </Typography>
                              )}
                            </Stack>
                            <ul
                              style={{
                                listStyleType: "none",
                                paddingLeft: 0,
                                height: 380,
                              }}
                            >
                              {plan.features.map((feature) => (
                                <li
                                  key={feature}
                                  style={{ position: "relative", height: 30 }}
                                >
                                  {feature}
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: -35,
                                    }}
                                  >
                                    <SvgIcon color="success">
                                      <CheckIcon />
                                    </SvgIcon>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </Box>
                          <Button
                            fullWidth
                            variant="contained"
                            // onClick={(e) => {
                            //   basicplan === "none"
                            //     ? checkout(e, plan?.id)
                            //     : statuscheck();
                            // }}
                            onClick={(e) => {
                              checkout(e, plan?.id);
                            }}
                          >
                            {plan?.buttontittle}
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <Divider sx={{ my: 3 }} />

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            ></Box>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                mt: 3,
              }}
            >
              {basicplan !== "none" && (
                <PropertyList>
                  {/* <PropertyListItem
                align="horizontal"
                divider
                label="Email id"
                value={createCustomer?.customer?.email}
              /> */}
                  <PropertyListItem
                    align="horizontal"
                    divider
                    label="Subscription end on"
                    value={formattedDate}
                  />
                  <PropertyListItem
                    align="horizontal"
                    divider
                    label="Subscription"
                    value={subscription === "monthly" ? "Monthly" : "Annually"}
                  />
                </PropertyList>
              )}
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ mt: 3 }}>{/* <AuthIssuer issuer={issuer} /> */}</Box>
      </div>
    </>
  );
};

export default CheckoutBilling;
